import React from 'react';
import { defineMessages } from 'react-intl';
import { DEFAULT_LANG } from '../components/locale/LocaleProvider';
import BaseLayout from '../components/layout/BaseLayout';
import SEO from '../components/SEO';
import ErrorPageContent from '../components/error/ErrorPageContent';

const messages = defineMessages({
  title: {
    id: 'pageNotFound.title',
    defaultMessage: 'Page Not Found'
  }
});

const PageNotFound = ({ location }) => (
  <BaseLayout
    locale={DEFAULT_LANG}
    render={({ intl }) => (
      <>
        <SEO
          title={intl.formatMessage(messages.title)}
          locale={DEFAULT_LANG}
          pathname={location.pathname}
          meta={[{ name: 'robots', content: 'noindex,nofollow' }]}
        />
        <ErrorPageContent />
      </>
    )}
  />
);

export default PageNotFound;
