import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/core';
import styles from '../../../styles';
import ErrorIcon from '../ErrorIcon';
import { ButtonLink } from '../../Button';

const ErrorPageContent = () => (
  <div css={style}>
    <ErrorIcon />
    <h1 className='h2 h-mt-xs h-mb-xxs'>
      <FormattedMessage id='error.404.heading' defaultMessage='Page not found' />
    </h1>
    <p className='p'>
      <FormattedMessage
        id='error.404.text'
        defaultMessage="We can't find the URL you're looking for. Let's try going back to the homepage."
      />
    </p>
    <div className='h-mt-sm'>
      <ButtonLink variant='large' to='/'>
        <FormattedMessage id='error.404.button' defaultMessage='Go to the homepage' />
      </ButtonLink>
    </div>
  </div>
);

const style = css`
  text-align: center;
  max-width: 40rem;
  margin: ${styles.spacing.md} auto ${styles.spacing.lg};
  padding: 0 ${styles.spacing.sm};
  @media (min-width: ${styles.breakpoint.sm}) {
    max-width: 60rem;
    margin: ${styles.spacing.xl} auto ${styles.spacing.xxl};
  }
`;

export default ErrorPageContent;
